import { BrowserRouter, Routes, Route } from 'react-router-dom'
import React from 'react'
import ReactDOM from 'react-dom/client'
import './css/main.css'
import './css/tailwind.css'
import TopPage from './pages/top'
import PrivacyPolicyPage from './pages/policy/privacy'
import UserPolicyPage from './pages/policy/user'
import SupportPage from './pages/support'
import TokushoPage from './pages/tokusho'
import RegisterInputPage from './pages/register/input'
import RegisterCompletePage from './pages/register/complete'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <BrowserRouter basename={process.env.REACT_APP_ROUTER_BASENAME}>
    <React.StrictMode>
      <Routes>
        {/* 以下は必要なページ */}
        <Route path="/" element={<TopPage />} />
        <Route path="/policy/privacy" element={<PrivacyPolicyPage />} />
        <Route path="/policy/user" element={<UserPolicyPage />} />
        <Route path="/support" element={<SupportPage />} />
        <Route path="/tokusho" element={<TokushoPage />} />
        {/* 会員登録 */}
        <Route path="/register/input" element={<RegisterInputPage />} />
        <Route path="/register/complete" element={<RegisterCompletePage />} />
      </Routes>
    </React.StrictMode>
  </BrowserRouter>
)
