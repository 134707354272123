import React from 'react'
import MainLayout from '../layouts/main'

const TokushoPage = () => {
  const content = (
    <>
      <div className="p-12 bg-gray-100 mb-12">
        <div className="mb-12">
          <p className="mb-8">●販売業者の名称：繋（きずな）</p>
          <p className="mb-8">●販売業者の住所：新潟県長岡市長町1-1664戊</p>
          <p>●連絡先 原則はコチラにお送りください。</p>
          <p className="pl-4">kizuna.app2023@gmail.com</p>
          <p className="pl-4 mb-4">070-2810-5789</p>
          <p className="mb-8">●販売業者の責任者：伊丹 純稀</p>
          <p>●商品の価格：商品ごとに表示された税込み価格に基づく。</p>
          <p className="mb-8">
            ただし、税抜き価格で表示している商品についてはこの限りではありません。
          </p>
          <p>●商品代金以外の必要金額：</p>
          <p>・消費税</p>
          <p>・通信料</p>
          <p>
            サービスをご利用の際に必要となる通信料・パケット代は、お客様の負担となります。
          </p>
          <p className="mb-8">
            （金額はお客様と通信会社間の契約内容によります）
          </p>
          <p className="mb-8">
            ●返品の取り扱い方法：商品の性質上、商品の交換又は返品は受け付けないものとします。
          </p>
          <p className="mb-8">●代金支払方法：クレジットカード</p>
          <p className="mb-8">
            ●支払時期：申込時に一回課金となり、クレジットカード会社が定める引き落とし日に支払いとなります。
          </p>
          <p className="mb-8">
            ●商品引渡し又はサービス提供の時期：購入手続完了後に利用可能となります。
          </p>
          <p className="mb-8">
            ●退会の条件：退会される場合は、当月末日までに退会申し込み画面からお申し込みを頂ければ、翌月以降の決済はされません。
          </p>
        </div>
      </div>
    </>
  )

  return (
    <MainLayout title="特定商取引法に基づく表記" content={content}></MainLayout>
  )
}

export default TokushoPage
