import React, { FC, ReactNode, useEffect } from 'react'
import { Link } from 'react-router-dom'
import MainCSS from '../css/main.css'
import TailwindCSS from '../css/tailwind.css'
import IconImg from '../images/icon.png'

type MainLayoutProps = {
  content: ReactNode
  title: string
}

const MainLayout: FC<MainLayoutProps> = (props) => {
  useEffect(() => {
    document.title = props.title
  }, [])
  return (
    <>
      <link rel="stylesheet" href={TailwindCSS} />
      <link rel="stylesheet" href={MainCSS} />
      <div>
        {/* ヘッダー */}
        <Link to="/">
          <div className="shadow-md bg-main-white flex items-center w-full py-2 px-4">
            <img width="40px" src={IconImg} className="rounded-md" />
            <span className="ml-3 text-lg">繋（きずな）</span>
          </div>
        </Link>

        {/* コンテンツ */}
        <div
          className="w-full bg-center bg-cover "
          style={{
            // backgroundImage: `url('${MainBgImg}')`,
            minHeight: 'calc(100vh - 120px)',
          }}
        >
          <div className="max-w-screen-md mx-auto px-4 py-8">
            <h1 className="text-2xl font-bold mb-8 text-center">
              {props.title}
            </h1>
            {props.content}
          </div>
        </div>

        {/* フッター */}
        <div className="flex justify-center items-center w-full py-4 bg-gray-400">
          <Link to="/policy/privacy">
            <p className="mx-4 text-white">プライバシーポリシー</p>
          </Link>
          <Link to="/support">
            <p className="mx-4 text-white">サポートページ</p>
          </Link>
          <Link to="/policy/user">
            <p className="mx-4 text-white">利用規約</p>
          </Link>
          <Link to="/tokusho">
            <p className="mx-4 text-white">特定商取引法に基づく表記</p>
          </Link>
        </div>
      </div>
    </>
  )
}

export default MainLayout
