import React from 'react'
import { UseFormRegister, FieldErrors } from 'react-hook-form'
import FieldLabel from './form-label'

interface IFormFieldProps {
  name: string
  label: string
  placeholder?: string
  register: UseFormRegister<any>
  errors: FieldErrors
  required?: boolean
  className?: string
}

const FormField: React.FC<IFormFieldProps> = ({
  name,
  label,
  placeholder,
  register,
  errors,
  required,
  className,
}) => {
  return (
    <div className={className}>
      {label && <FieldLabel label={label} required={required} />}
      <textarea
        {...register(name, { required })}
        rows={6}
        placeholder={placeholder}
        className={`mt-1 block w-full px-3 py-2 border border-gray-300 ${
          errors[name] ? 'border-red-500' : 'border-gray-300'
        } bg-white rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
      />
      {errors[name] && (
        <p className="mt-2 text-sm text-red-600" id={`${name}-error`}>
          必須です。
        </p>
      )}
    </div>
  )
}

export default FormField
