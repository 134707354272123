import React from 'react'
import { UseFormRegister, FieldErrors } from 'react-hook-form'
import FieldLabel from './form-label'

interface ISelectFieldProps {
  name: string
  label?: string
  options: { value: string; label: string }[]
  register: UseFormRegister<any>
  required?: boolean
  className?: string
}

const SelectField: React.FC<ISelectFieldProps> = ({
  name,
  label,
  options,
  register,
  required,
  className,
}) => {
  return (
    <div className={className}>
      {label && <FieldLabel label={label} required={required} />}
      <select
        id={name}
        {...register(name, { required })}
        className={`mt-1 block w-full pl-2 pr-1 py-2 text-base border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  )
}

export default SelectField
