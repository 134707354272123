import React from 'react'
import MainLayout from '../../layouts/main'
import AppleImg from '../../images/apple.svg'
import GoogleImg from '../../images/google.png'

const RegisterCompletePage = () => {
  const content = (
    <>
      <div className="mb-12 text-center">
        <p className="font-bold mb-4">会員登録が完了しました。</p>
        <p>引き続きアプリで繋（きずな）をお楽しみください</p>
      </div>
      <div className="flex items-center justify-center mb-12">
        <a
          href="https://apps.apple.com/jp/app/id6448905903"
          target="_blank"
          rel="noreferrer"
        >
          <img width="160px" className="mr-4" src={AppleImg} />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=jp.co.kizuna&hl=ja-JP"
          target="_blank"
          rel="noreferrer"
        >
          <img width="202px" src={GoogleImg} />
        </a>
      </div>
    </>
  )

  return <MainLayout title="登録完了" content={content}></MainLayout>
}

export default RegisterCompletePage
