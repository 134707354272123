import React from 'react'
import MainLayout from '../../layouts/main'

const PrivacyPolicyPage = () => {
  const content = (
    <>
      <div className="p-12 bg-gray-100">
        <section className="mb-8">
          <h2 className="text-xl font-bold mb-4">第1条（会員規約）</h2>
          <p>
            1．KIZUNA（以下「当社」といいます。）は、KIZUNAアプリ会員規約（以下「会員規約」といいます。）を定め、会員規約に従い、当社が運営するサービス（以下「本サービス」といいます。）をKIZUNA会員（以下「会員」といいます。）に提供します。
          </p>
          <p>
            2．本サービスのヘルプ、ガイドページその他本サービスを説明するページも、別段の定めがある場合を除き、会員規約の一部を構成するものとし、これらを合わせて会員規約とします。
          </p>
          <p>
            3．会員は、会員規約に同意の上、本サービスを利用するものとします。
          </p>
        </section>
        <section className="mb-8">
          <h2 className="text-xl font-bold mb-4">第2条（規約の変更）</h2>
          <p>
            1．当社は、必要と判断した場合、事前に会員に通知することなく、いつでも会員規約を変更することができるものとします。
          </p>
          <p>
            2．変更後の会員規約については、本サービス上又は当社が運営するアプリ上に表示した時点で効力を生じるものとし、変更後に本サービスを利用した場合は変更後の会員規約に同意したものとみなします。
          </p>
        </section>
        <section className="mb-8">
          <h2 className="text-xl font-bold mb-4">
            第3条（会員に対する通知方法・会員からの連絡方法）
          </h2>
          <p>
            1．当社から会員に対する通知は、電子メール、アプリでの掲示その他当社が適当と判断する方法により行います。
          </p>
          <p>
            2．通知が電子メールで行われる場合、当社が会員宛に電子メールを発信した時点で当該通知が会員に到達したものとみなします。
          </p>
          <p>
            3．通知をアプリに掲示する場合、通知がウェブサイトに掲載された時点で当該通知が会員に到達したものとみなします。
          </p>
          <p>
            4．会員から当社に対する連絡は、当社が指定する方法に従い、行うものとします。
          </p>
        </section>
        <section className="mb-8">
          <h2 className="text-xl font-bold mb-4">第4条（会員登録）</h2>
          <p>
            1．本サービスの利用を希望する者は、当社が指定する方法により、会員登録を行うものとします。
          </p>
          <p>
            2．本サービスの利用には月額利用料がかかりませんが。万が一本サービスの運営に支障をきたす恐れのある行為などが発覚した際には、強制的にサービスの利用を停止出来るものとします。
          </p>
        </section>
        <section className="mb-8">
          <h2 className="text-xl font-bold mb-4">
            第5条（ユーザーID・パスワード）
          </h2>
          <p>
            1．会員は、自己のユーザーID及びパスワードを、自己の責任において厳重に管理するものとし、第三者による不正利用について当社は一切の責任を負わないものとします。
          </p>
          <p>
            2．会員は、自己のユーザーID及びパスワードを第三者に使用させ、譲渡し、又は貸与してはならないものとします。
          </p>
          <p>
            3．当社は、登録されたユーザーID及びパスワードで本サービスが利用された場合、当該ユーザーID及びパスワードの会員が利用したものとみなし、その効果は当該会員に帰属するものとします。
          </p>
          <p>
            4．会員は、自己のユーザーID及びパスワードを第三者に知られた場合及び第三者に使用されている疑いがあることが判明した場合は、直ちに当社に報告を行い、当社の指示に従うものとします。
          </p>
        </section>
        <section className="mb-8">
          <h2 className="text-xl font-bold mb-4">第6条（譲渡等の禁止）</h2>
          <p>
            当社は、会員が会員たる地位、会員たる地位に基づき本サービスを利用する権利並びに会員の権利又は義務を第三者に譲渡、販売、貸与、承継、使用許諾その他処分をすることを禁止します。
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-bold mb-4">第7条（登録情報の変更）</h2>
          <p>
            1．会員は、メールアドレス、電話番号、クレジットカード情報その他当社に登録した情報に変更が生じた場合、直ちに、登録情報の変更を行う義務を負うものとします。
          </p>
          <p>
            2．登録情報の変更を怠ったことにより会員に生じた不利益、損害について、当社は一切の責任を負わないものとします。
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-bold mb-4">第8条（退会）</h2>
          <p>
            1．会員が本サービスからの退会を希望する場合は、当社所定の方法により、手続を行うものとします。
          </p>
          <p>
            2．会員は、退会により、会員たる地位を喪失し、本サービスの利用ができなくなるものとします。また、退会完了後は、喪失した会員たる地位を復元することはできません。
          </p>
          <p>
            3．会員は、当社に対し金銭債務その他債務を負担する場合、退会によりこれを免れるものではありません。
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-bold mb-4">第9条（サービス利用環境）</h2>
          <p>
            1．会員は、本サービスを利用するための利用端末、電子機器、通信機器、ソフトウェア、インターネット接続環境を自己の責任と費用で準備するものとします。
          </p>
          <p>
            2．本サービスを利用するあたり発生する通信費等は、会員が負担するものとします。
          </p>
          <p>
            3．プロキシサーバー、VPNサーバー等を経由して本サービスを利用する場合、正常に本サービスを利用することができない場合があります。
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-bold mb-4">第10条（会員の自己責任）</h2>
          <p>
            1．会員は、本サービスの利用に関連して、会員と第三者との間で紛争が生じた場合、会員の責任と費用において、当該紛争を解決するものとします。
          </p>
          <p>
            2．会員は、本サービスの利用に関連して、第三者に損害を与えた場合、会員の責任と費用において、当該損害を賠償するものとします。
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-bold mb-4">第11条（決済）</h2>
          <p>1．個人会員は無料でご利用いただけます。</p>
          <p>
            2．法人会員は、本サービスの利用料を当社が指定する支払方法で支払うものとします。
          </p>
          <p>
            3．会員は、当社が指定するクレジットカード事業者（以下「決済会社」といいます。）が提供する決済手段で対価の決済を行うことができるものとします。
          </p>
          <p>
            4．前項の場合、法人会員は、決済会社が定める規約等に従い決済会社と契約を締結するものとし、会員と決済会社との間で紛争が生じた場合、会員の責任で当該紛争を解決するものとし、当社はこれに関与しないものとします。
          </p>
          <p>
            5．決済手数料（当社が指定するものに限ります。）は、法人会員が負担するものとします。
          </p>
          <p>
            6．法人会員は、当社に対し、理由の如何を問わず、支払い済み対価の返還を請求することはできないものとします。
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-bold mb-4">第12条（禁止行為）</h2>
          <p>
            1．当社は、会員による本サービスの利用に際して、次の各号に定める行為を禁止します。
          </p>
          <p>
            （1）当社又は第三者の著作権、商標権、意匠権、特許権、実用新案権その他知的財産権を侵害する行為
          </p>
          <p>
            （2）当社又は第三者の財産権、肖像権、パブリシティ権、人格権、名誉権、プライバシー権等を侵害する行為
          </p>
          <p>（3）公序良俗に反する行為</p>
          <p>（4）法令に反する行為</p>
          <p>
            （5）犯罪的行為、犯罪的行為に結びつく行為及び犯罪的行為を助長する行為
          </p>
          <p>
            （6）児童ポルノ、ポルノ、わいせつ物の頒布等を規制する法令に違反する行為
          </p>
          <p>（7）虚偽の情報を投稿する行為</p>
          <p>（8）当社のサーバーに過度の負担を及ぼす行為</p>
          <p>（9）本サービスの運営を妨害する行為</p>
          <p>
            （10）本サービスを、本サービスの本来のサービス提供目的とは異なる目的で利用する行為
          </p>
          <p>
            （11）本サービスで提供されるコンテンツに施されたセキュリティ、DRM等の技術的保護手段を削除し、回避し、無効化する行為
          </p>
          <p>（12）当社又は第三者に不利益を与える行為</p>
          <p>（13）当社又は第三者に対する誹謗中傷、脅迫、嫌がらせを行う行為</p>
          <p>（14）第三者を差別又は差別を助長する行為</p>
          <p>
            （15）第三者の承諾なく個人情報又はプライバシー情報を収集し、公開する行為
          </p>
          <p>
            （16）選挙の事前運動、選挙運動又はこれらに類似する行為及び公職選挙法に抵触する行為
          </p>
          <p>
            （17）会員又は第三者の営業に関する宣伝を目的として本サービスを利用する行為
          </p>
          <p>
            （18）ねずみ講、マルチ商法を勧誘する目的で本サービスを利用する行為
          </p>
          <p>（19）本サービスの外に接続するリンクを投稿する行為</p>
          <p>（20）当社の業務に支障をきたす行為</p>
          <p>（21）虚偽の情報を本サービスに登録する行為</p>
          <p>（22）KIZUNA会員規約又は個別規約で禁止する行為</p>
          <p>（23）その他当社が不適切と判断する行為</p>
          <p>
            2．前項の禁止行為に該当するか否かの判断は、当社の裁量により行うものとし、当社は当社の判断について説明責任を負わないものとします。
          </p>
          <p>
            3．当社は、会員の行為が、第１項の禁止行為に該当すると判断した場合、事前に通知することなく、次の各号に定めるいずれか又は全ての措置を講じることができるものとします。
          </p>
          <p>（1）本サービスの利用制限</p>
          <p>（2）会員登録の解除による退会処分</p>
          <p>（3）その他当社が必要と判断する行為</p>
          <p>
            4．前項の措置により会員に生じた損害について当社は一切の責任を負わないものとします。
          </p>
          <p>
            5．当社は、会員に対し、会員規約に違反する行為により当社に生じた一切の損害、損失、費用（訴訟費用及び弁護士費用を含みます。）の賠償を求めることができるものとします。
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-bold mb-4">第13条（サービス利用制限）</h2>
          <p>
            1．当社は、次の各号に該当する場合、会員による本サービスの利用を制限することができるものとします。
          </p>
          <p>
            （1）会員のユーザーID及びパスワードが第三者に利用されている疑いがある場合
          </p>
          <p>（2）登録情報に虚偽の情報が含まれている疑いがある場合</p>
          <p>
            （3）対価等の支払いが遅延している場合又は支払の遅延が生じるおそれがある場合
          </p>
          <p>（4）当社から会員に対し連絡がとれない場合</p>
          <p>
            （5）警察、裁判所、検察庁、弁護士会、消費者センターまたはこれらに準じた権限を有する機関からサービス利用制限の指導があった場合
          </p>
          <p>（6）その他当社が必要と判断した場合</p>
          <p>
            2．前項の措置により会員に生じた損害について当社は一切の責任を負わないものとします。
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-bold mb-4">第14条（当社による解除）</h2>
          <p>
            1．当社は、会員が次の各号のいずれかに該当した場合、何らの通知等を行わずに、会員登録を解除し、退会させることができるものとします。
          </p>
          <p>（1）登録情報に虚偽の情報が含まれている場合</p>
          <p>（2）対価等の支払いが遅延している場合</p>
          <p>
            （3）会員の信用状態が悪化し、対価等の支払いの継続が困難である場合
          </p>
          <p>（4）過去に当社から退会処分を受けていた場合</p>
          <p>
            （5）会員の相続人等から会員が死亡した旨の連絡があった場合又は当社が会員の死亡の事実を確認できた場合
          </p>
          <p>
            （6）反社会的勢力等（暴力団、暴力団構成員、準構成員、暴力団関係企業、総会屋、社会運動標ぼうゴロ、政治活動標ぼうゴロ、特殊知能暴力集団等又はこれらに準ずる者）に該当すると当社が判断した場合
          </p>
          <p>（7）破産手続開始、民事再生手続開始の申立てがあった場合</p>
          <p>（8）当社からの要請に対し誠実に対応しない場合</p>
          <p>（9）その他当社が不適当と判断した場合</p>
          <p>
            2．前項の措置により会員に生じた損害について当社は一切の責任を負わないものとします。
          </p>
          <p>
            3．第1項の措置により、退会した会員は、退会時に期限の利益を喪失し、直ちに、当社に対し負担する全ての債務を履行するものとします。
          </p>
          <p>
            4．第8条第2項および第3項の規定は、本条による退会の場合について準用するものとします。
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-bold mb-4">
            第15条（サービスの提供の中断）
          </h2>
          <p>
            1．当社は、次の各号に該当する場合、本サービスの提供を中断することができるものとします。
          </p>
          <p>（1）本サービスの保守・点検を行う場合</p>
          <p>（2）火災、停電等の事故が発生した場合</p>
          <p>（3）天変地異等の非常事態が発生した場合</p>
          <p>（4）システム障害、ネットワーク障害が発生した場合</p>
          <p>（5）当社の業務上やむを得ない事由が生じた場合</p>
          <p>（6）その他当社が必要と判断した場合</p>
          <p>
            2．前項の措置により会員が本サービスを利用できないことにより生じた損害等について当社は責任を負わないものとします。
          </p>
          <p>
            3．前2項の定めにかかわらず、当社の責めに帰すべき事由により本サービスが連続して72時間以上利用不能状態が継続した場合、当社は、該当サービスで会員に現実に生じた損害を、当社の判断により次のいずれかの方法により賠償します。ただし、会員は損害発生時から1ヶ月以内に当社に請求を行うものとし、期間内に請求を行わない場合はその権利を失うものとします。
          </p>
          <p>
            （1）今後決済する該当サービスの利用料金から、賠償額に相当する金額を減額する方法
          </p>
          <p>（2）賠償額に相当する金額相当の該当サービス利用権を付与する方法</p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-bold mb-4">
            第16条（サービスの変更、追加、廃止）
          </h2>
          <p>
            1．当社は、いつでも、本サービスの全部又は一部を変更（本サービスの内容、本サービスの仕様、本サービスの利用料及び本サービスで販売する商品価格の変更を含みますが、これらに限りません。）、追加又は廃止することができるものとします。
          </p>
          <p>
            2．当社は、前項による本サービスの全部又は一部の変更、追加又は廃止について、何ら責任を負うものではありません。
          </p>
          <p>
            3．当社は、第1項の規定により本サービスの全部又は重要な一部を変更、追加又は廃止するときは、会員に対し、相当な期間前までにその旨を通知します。ただし、緊急又はやむを得ない場合はこの限りではありません。
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-bold mb-4">第17条（免責）</h2>
          <p>
            1．当社は、本サービスが、会員の特定の目的に適合すること及び会員が期待する品質、価値を有することを何ら保証するものではありません。
          </p>
          <p>
            2．当社は、本サービスの完全性、正確性、確実性、信頼性、有用性等について何ら保証するものではありません。また、当社は、本サービスに事実上又は法律上の瑕疵がないことを保証するものではありません。
          </p>
          <p>
            3．当社は、会員による本サービスの利用により生じた不利益、損害について、一切の責任を負わないものとします。
          </p>
          <p>
            4．当社は、会員が本サービスを利用できないことにより生じた不利益、損害について、一切の責任を負わないものとします。
          </p>
          <p>
            5．当社と会員との間の本サービスの利用に関する契約が、消費者契約法の消費者契約に該当する場合、会員規約のうち、当社の損害賠償責任を完全に免責する規定は適用されないものとします。この場合において会員に発生した損害が当社の債務不履行又は不法行為に基づくときは、当社は、会員が当社に本サービス利用の対価として直近1ヶ月間に支払った金額を上限として損害賠償責任を負うものとします。ただし、当社に故意又は重大な過失がある場合はこの限りではありません。
          </p>
          <p>
            6．当社が管理するサイトに設置されたリンク先のうち、第三者が管理するサイトは当社が管理するものではないため、当社は当該サイトについて一切の責任を負わないものとします。
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-bold mb-4">第18条（知的財産権）</h2>
          <p>
            1．本サービスで提供される映像、音声、プログラム、文字、画像、イラスト、デザイン、商標、ロゴマーク、その他一切の情報（以下「本コンテンツ」といいます。）の著作権、著作隣接権、商標権、意匠権等の知的財産権は、当社又は当社に利用許諾した第三者に帰属します。
          </p>
          <p>
            2．会員は、本コンテンツについて、当社又は第三者の権利を侵害する一切の行為をしてはならないものとします。
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-bold mb-4">第19条（事業譲渡）</h2>
          <p>
            当社は、本サービスに関する事業を第三者に譲渡した場合には、当該事業譲渡に伴い、本サービスの運営者たる地位、会員規約に基づく権利及び義務並びに会員の登録情報その他情報を当該事業譲渡の譲受人に譲渡することができるものとし、会員は、会員たる地位、会員規約に基づく権利及び義務並びに会員の登録情報その他情報の譲渡につきあらかじめ同意するものとします。
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-bold mb-4">第20条（業務委託）</h2>
          <p>
            1．当社は、本サービスの業務の全部又は一部を、第三者（外国にある第三者を含みます。）に委託させて行わせることができるものとし、会員はこれにあらかじめ同意するものとします。
          </p>
          <p>
            2．前項の場合において、当社が必要と判断した場合、当社は、個人情報を第三者（外国にある第三者を含みます。）に提供することができるものとし、会員はこれにあらかじめ同意するものとします。
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-bold mb-4">第21条（損害賠償責任）</h2>
          <p>
            会員は、本サービスの利用に関連して当社に損害を与えた場合、当該損害を賠償する責任を負うものとします。
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-bold mb-4">
            第22条（準拠法及び専属的合意管轄裁判所）
          </h2>
          <p>1．会員規約は、日本法に準拠して解釈されるものとします。</p>
          <p>
            2．本サービス又は会員規約に関し、当社と会員の間で生じた紛争の解決については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-bold mb-4">
            第23条（個人情報の取り扱い）
          </h2>
          <p>
            1．当社は、会員の個人情報を、当社が定める「プライバシーポリシー」（
            <a href="https://kizuna-app.biz/app/policy/privacy">
              https://kizuna-app.biz/app/policy/privacy
            </a>
            ）に従い取り扱います。
          </p>
          <p>
            2．第11条による決済会社に提供した個人情報等においては、当社は保有しておりませんので、この限りではありません。
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-xl font-bold mb-4">
            第24条（外部サービスとの連携）
          </h2>
          <p>
            1．会員は、第三者が運営するサービス（当社が指定するサービスであり、以下「外部サービス」といいます。また、外部サービスを運営する会社を、以下「外部サービス運営会社」といいます。）において会員が作成したアカウントを利用して、第4条に定める会員登録を行うことができます。当該会員登録は、当社が定める手順による手続（以下「連携手続」といいます。）により行うことが可能です。
          </p>
          <p>
            2．会員は、連携手続を行うにあたり、外部サービスに登録された会員に関する情報（以下「外部サービス情報」といいます。）を当社が取得し、会員登録の際に表示することを承諾するものとします。
          </p>
          <p>
            3．当社は、取得した外部サービス情報に含まれる個人情報を、当社が定める「個人情報の取り扱いについて」に従い取り扱います。
          </p>
          <p>
            4．外部サービスへのアカウントの登録・利用（書き込み内容及び投稿などの行為を含みます。）について、会員は、外部サービス運営会社が定める各規約に従うものとします。
          </p>
          <p>
            5．会員は、外部サービスにおけるアカウントの登録・管理などを自己の責任において行うものとします。
          </p>
          <p>
            6．会員の外部サービスアカウント及びパスワードの管理不十分、使用上の過誤、第三者の使用などにより、会員または第三者に損害が生じたとしても、その責任は会員が負うものとし、当社は一切責任を負いません。また、かかる事由により、当社が損害を負った場合には、会員はその一切の責任を負い、当社が被った一切の損害を賠償するものとします。
          </p>
          <p>
            7．外部サービスを利用する場合、会員は、自己の責任において当該サービスを利用するものとし、当社は、当該サービスを利用したことにより生じた損害、当該サービスの運営者・会員などとの間に生じたトラブルその他の当該サービスに関連する一切の事項について何らの責任も負わないものとします。
          </p>
        </section>

        <section className="mb-8">
          <p>令和5年5月15日</p>
          <p>KIZUNA</p>
        </section>
      </div>
    </>
  )

  return <MainLayout title="利用規約" content={content}></MainLayout>
}

export default PrivacyPolicyPage
