export const hours = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
]

export const minutes = [
  '00',
  '05',
  '10',
  '15',
  '20',
  '25',
  '30',
  '35',
  '40',
  '45',
  '50',
  '55',
]

export const prefectures = [
  '北海道',
  '青森県',
  '岩手県',
  '宮城県',
  '秋田県',
  '山形県',
  '福島県',
  '茨城県',
  '栃木県',
  '群馬県',
  '埼玉県',
  '千葉県',
  '東京都',
  '神奈川県',
  '新潟県',
  '富山県',
  '石川県',
  '福井県',
  '山梨県',
  '長野県',
  '岐阜県',
  '静岡県',
  '愛知県',
  '三重県',
  '滋賀県',
  '京都府',
  '大阪府',
  '兵庫県',
  '奈良県',
  '和歌山県',
  '鳥取県',
  '島根県',
  '岡山県',
  '広島県',
  '山口県',
  '徳島県',
  '香川県',
  '愛媛県',
  '高知県',
  '福岡県',
  '佐賀県',
  '長崎県',
  '熊本県',
  '大分県',
  '宮崎県',
  '鹿児島県',
  '沖縄県',
]

export const holidays = [
  {
    label: '月曜',
    value: 'mon',
  },
  {
    label: '火曜',
    value: 'tue',
  },
  {
    label: '水曜',
    value: 'wed',
  },
  {
    label: '木曜',
    value: 'thu',
  },
  {
    label: '金曜',
    value: 'fri',
  },
  {
    label: '土曜',
    value: 'sat',
  },
  {
    label: '日曜',
    value: 'sun',
  },
  {
    label: '祝日',
    value: 'hol',
  },
  {
    label: '年末年始',
    value: 'new',
  },
  {
    label: '不定休',
    value: 'any',
  },
]
