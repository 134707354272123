import React from 'react'
import MainLayout from '../layouts/main'

const TokushoPage = () => {
  const content = (
    <>
      <div className="p-12 bg-gray-100 mb-12">
        <h2 className="text-xl font-bold mb-12">よくある質問</h2>
        <div className="mb-12">
          <p className="font-bold mb-4">▼ 誤った生年月日を登録してしまった</p>
          <p>会員情報の編集画面から変更いただけます。</p>
        </div>
        <div className="mb-12">
          <p className="font-bold mb-4">▼ KIZUNAポイントとは</p>
          <p>全店共通で使用出来るポイントです。</p>
        </div>
        <div className="mb-12">
          <p className="font-bold mb-4">▼ 自社ポイントとは</p>
          <p>クエストを依頼し、達成した店舗のみで使用可能なポイントです。</p>
        </div>
        <div className="mb-12">
          <p className="font-bold mb-4">▼ アプリを退会したい</p>
          <p>
            会員登録後はマイページから退会することが可能です。退会した場合それまでに登録したユーザー情報等については削除されます。
          </p>
        </div>
      </div>

      <div className="p-12 bg-gray-100">
        <h2 className="text-xl font-bold mb-6">その他お問合せ先</h2>
        <p>伊丹 純稀</p>
        <p>〒940-0053新潟県長岡市長町</p>
        <p>【電話番号】 070-2810-5789</p>
        <p>【メールアドレス】 itami0506@gmail.com</p>
      </div>
    </>
  )

  return <MainLayout title="サポートページ" content={content}></MainLayout>
}

export default TokushoPage
