import React, { useState, FormEvent } from 'react'
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js'
import LoadingDialog from '../dialog/loading-dialog'
import '../../css/stripe-form.css'
import {
  PaymentIntent,
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from '@stripe/stripe-js'

interface CheckoutFormProps {
  amount: number
  paymentIntentId: string
  successFunc: (paymentIntent: PaymentIntent) => Promise<void>
  closeFunc: () => void
}

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
}

const StripeCheckoutForm: React.FC<CheckoutFormProps> = ({
  amount,
  paymentIntentId,
  successFunc,
  closeFunc,
}) => {
  const stripe = useStripe()
  const elements = useElements()
  const [error, setError] = useState<string | null>(null)
  const [processing, setProcessing] = useState<boolean>(false)
  const [cardError, setCardError] = useState<string | null>(null)
  const [expiryError, setExpiryError] = useState<string | null>(null)
  const [cvcError, setCvcError] = useState<string | null>(null)

  const handleCardChange = (event: StripeCardNumberElementChangeEvent) => {
    setCardError(event.error ? event.error.message : null)
  }

  const handleExpiryChange = (event: StripeCardExpiryElementChangeEvent) => {
    setExpiryError(event.error ? event.error.message : null)
  }

  const handleCvcChange = (event: StripeCardCvcElementChangeEvent) => {
    setCvcError(event.error ? event.error.message : null)
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!stripe || !elements) {
      return
    }

    setProcessing(true)

    // 個別の要素を取得
    const cardNumberElement = elements.getElement(CardNumberElement)
    const cardExpiryElement = elements.getElement(CardExpiryElement)
    const cardCvcElement = elements.getElement(CardCvcElement)

    if (cardNumberElement && cardExpiryElement && cardCvcElement) {
      const result = await stripe.confirmCardPayment(paymentIntentId, {
        payment_method: {
          card: cardNumberElement,
        },
      })

      if (result.error) {
        setError(result.error.message || '決済に失敗しました。')
      } else if (
        result.paymentIntent &&
        result.paymentIntent.status === 'succeeded'
      ) {
        // 決済成功
        console.log(result)
        setProcessing(false)
        successFunc(result.paymentIntent)
        return
      } else {
        // それ以外の状況に対応するエラーメッセージをセットします。
        setError('決済に失敗しました。')
      }
    }

    setProcessing(false)
  }

  return (
    <>
      {processing && <LoadingDialog></LoadingDialog>}
      <form onSubmit={handleSubmit}>
        <div className="mb-8">
          <div className="p-4 rounded bg-slate-100">
            <p className="mb-2">
              システム利用料（初期費用のみ）{' '}
              {new Intl.NumberFormat('en-US').format(amount)}円
            </p>
            <p className="text-sm">
              ・スポンサー登録には初回のみシステム利用料がかかります。
            </p>
            <p className="text-sm">
              ・決済方法はクレジットカードのみ可能です。
            </p>
          </div>
        </div>
        <div className="mb-12">
          <label className="mb-2 block">
            クレジットカード番号
            <CardNumberElement
              options={CARD_ELEMENT_OPTIONS}
              onChange={handleCardChange}
            />
            {cardError && <div className="text-red-400">{cardError}</div>}
          </label>
          <label className="mb-2 block">
            有効期限
            <CardExpiryElement
              options={CARD_ELEMENT_OPTIONS}
              onChange={handleExpiryChange}
            />
            {expiryError && <div className="text-red-400">{expiryError}</div>}
          </label>
          <label className="mb-2 block">
            CVC
            <CardCvcElement
              options={CARD_ELEMENT_OPTIONS}
              onChange={handleCvcChange}
            />
            {cvcError && <div className="text-red-400">{cvcError}</div>}
          </label>
        </div>

        <div className="flex flex-wrap items-center justify-center">
          <button
            className="w-44 text-center mx-4 mb-4 block bg-orange rounded-md py-2 text-white"
            disabled={!stripe || processing}
            type="submit"
          >
            決済して登録する
          </button>
          <button
            className="w-44 text-center mx-4 mb-4 block bg-gray-500 rounded-md py-2 text-white"
            onClick={closeFunc}
          >
            閉じる
          </button>
        </div>

        {error && <div>{error}</div>}
      </form>
    </>
  )
}

export default StripeCheckoutForm
