import React from 'react'

interface ILabelProps {
  label: string
  required?: boolean
}

const FieldLabel: React.FC<ILabelProps> = ({ label, required }) => {
  return (
    <div className="flex items-center justify-start">
      <label className="block text-sm font-medium text-gray-700">{label}</label>
      {required && <span className="text-red-500 ml-2">*</span>}
    </div>
  )
}

export default FieldLabel
