import React from 'react'

const Loading: React.FC = () => {
  return (
    <div
      className="fixed top-0 left-0 w-screen h-screen bg-black/50 flex items-center justify-center"
      style={{ zIndex: 9999999999 }}
    >
      <div className="flex justify-center" aria-label="読み込み中">
        <div className="animate-ping h-4 w-4 bg-orange rounded-full"></div>
      </div>
    </div>
  )
}

export default Loading
