import React from 'react'
import MainLayout from '../layouts/main'
import IconImg from '../images/icon.png'
import AppleImg from '../images/apple.svg'
import GoogleImg from '../images/google.png'
import { Link } from 'react-router-dom'

const TopPage = () => {
  const content = (
    <>
      <div className="flex items-center mb-12">
        <img width="80px" src={IconImg} className="rounded-md mr-6" />
        <p>
          繋（きずな）は、人と人とをつなぐコミュニケーションアプリです。地域のスポンサーが一目で分かるマップ機能と、ヘルプミー機能でスポンサーや他のユーザーと助け合い、ポイントを貯めて商品をお得に購入できます。
        </p>
      </div>
      <div className="flex items-center mb-12">
        <a
          href="https://apps.apple.com/jp/app/id6448905903"
          target="_blank"
          rel="noreferrer"
        >
          <img width="160px" className="mr-4" src={AppleImg} />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=jp.co.kizuna&hl=ja-JP"
          target="_blank"
          rel="noreferrer"
        >
          <img width="202px" src={GoogleImg} />
        </a>
      </div>
      <div className="mb-16">
        <Link to="register/input">
          <a className="text-blue-600 underline">スポンサー登録はこちら</a>
        </Link>
      </div>
    </>
  )

  return <MainLayout title="繋（きずな）" content={content}></MainLayout>
}

export default TopPage
